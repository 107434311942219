import React from 'react';
import HOMEICON from '@images/common/menu_home.png';
import HOMEICONACTIVE from '@images/home/menu_home_active.png';
const Home = React.lazy(() => import('@pages/home'));
const Dynamic = React.lazy(() => import('@pages/dynamic'));
const DynamicDetail = React.lazy(() => import('@pages/dynamic/detail/index'));
const Center = React.lazy(() => import('@pages/center'));
const About = React.lazy(() => import('@pages/about'));

export const RouteConfig = [
  {
    path: '/',
    name: '首页',
    icon: HOMEICON,
    activeIcon: HOMEICONACTIVE,
    alias: 'ཁ་པར་གསར',
    component: <Home />,
    title: '西藏天慈生物科技有限公司官网',
  },
  {
    path: '/center/:type?',
    name: '品牌中心',
    alias: 'ཚོང་རྟགས་ལྟེ་གནས།',
    component: <Center />,
    key: ['/center'],
    title: '品牌中心-天慈生物科技',
  },
  {
    path: '/dynamic',
    name: '企业动态',
    alias: 'ཁེ་ལས་ཀྱི་འགུལ་རྣམ།',
    component: <Dynamic />,
    title: '企业动态-天慈生物科技',
  },
  {
    path: '/lifeArt',
    name: '天慈生活',
    alias: 'ཐེན་ཚི་འཚོ་བ།',
    component: <Dynamic />,
    title: '天慈生活-天慈生物科技',
    key: ['/lifeArt', '/lifeTibet'],
  },
  {
    path: '/lifeTibet',
    name: '天慈生活',
    alias: 'ཁ་པར་གསར',
    component: <Dynamic />,
    hide: true,
    title: '天慈生活-天慈生物科技',
  },
  {
    path: '/dynamic/detail/:id',
    name: '详情',
    alias: 'ཐེན་ཚིའི་སྐོར།',
    component: <DynamicDetail />,
    hide: true,
    title: '西藏天慈生物科技有限公司官网',
  },
  {
    path: '/lifeArt/detail/:id',
    name: '详情',
    alias: 'ཁ་པར་གསར',
    component: <DynamicDetail />,
    hide: true,
    title: '西藏天慈生物科技有限公司官网',
  },
  {
    path: '/lifeTibet/detail/:id',
    name: '详情',
    alias: 'ཁ་པར་གསར',
    component: <DynamicDetail />,
    hide: true,
    title: '西藏天慈生物科技有限公司官网',
  },
  {
    path: '/about/:type?',
    name: '关于天慈',
    alias: 'ཁ་པར་གསར',
    component: <About />,
    title: '关于天慈-天慈生物科技',
    key: ['/about'],
  },
];
