import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteConfig } from '@const/route';
import Header from '@common/header';
import Footer from '@common/footer';
import Loading from '@common/loading';

export default function Index() {
  return (
    <React.Fragment>
      <Header />
      <Suspense fallback={<Loading />}>
        <Switch>
          {RouteConfig.map(v => (
            <Route
              path={v.path}
              key={v.path}
              render={() => {
                document.title = v.title;
                window.scrollTo(0, 0);
                return v.component;
              }}
              exact
            />
          ))}
        </Switch>
      </Suspense>
      <Footer />
    </React.Fragment>
  );
}
