import React from 'react';
import LazyLoad from 'react-lazyload';
import LOCATION from '@images/common/location.png';
import miniprogram from '@images/home/miniprogram.png';
import './index.less';
import { Link } from 'react-router-dom';

class Footer extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <div className='footer'>
          <div className='common-container footer-container'>
            <div className='footer-main'>
              <div className='footer-item'>
                <div className='footer-item_title'>关于天慈</div>
                <Link to='/about/intro'>企业简介</Link>
                <Link to='/about/process'>里程碑</Link>
              </div>
              <div className='footer-item'>
                <div className='footer-item_title'>联系我们</div>
                <p className='weixin'>@天慈官方微信公众号</p>
                <p>客户服务：028-66336918</p>
              </div>
            </div>
            <div className='footer-code'>
              <LazyLoad height='1.5rem' offetTop={100}>
                <img alt='二维码' src={miniprogram} />
              </LazyLoad>
              <p>扫描二维码访问商城</p>
            </div>
          </div>
        </div>
        <div className='common-container copyright'>
          <div className='copyright-address'>
            <LazyLoad height='.15rem' offetTop={100}>
              <span>
                <img src={LOCATION} alt='' />
              </span>
            </LazyLoad>
            <span className='address'>
              总部地址：西藏自治区拉萨市达孜工业园区302号
              <span className='space'>/</span>
              <span className='subAddress'>
                运营中心地址：四川·成都·青羊区蜀金路1号金沙万瑞中心C座1502
              </span>
            </span>
          </div>
          <div>
            <span>
              <a href='https://beian.miit.gov.cn/' target='_blank' rel="noreferrer">
                藏ICP备16000197号-1
              </a>
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
