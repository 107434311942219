import React from 'react';
import { withRouter } from 'react-router-dom';
import { RouteConfig } from '@const/route';
import LOGO from '@images/common/logo.png';
import TITLE from '@images/common/title.png';
import NAVLOGO from '@images/common/nav_fixed_logo.png';
import './index.less';

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scrollTop: 0,
    };
  }

  componentDidMount() {
    window.onscroll = () => {
      this.setState({
        scrollTop:
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop,
      });
    };
  }

  goPage = path => {
    const { history } = this.props;
    history.push(path.split('/:')[0]);
  };

  render() {
    const scrollTop = this.state.scrollTop > 112;
    const {
      location: { pathname },
    } = this.props;
    return (
      <div className={scrollTop ? 'header header-fixed' : 'header'}>
        <div className='logo'>
          <div className='logo-icon'>
            <img alt='logo' src={LOGO} />
          </div>
          <div className='logo-text'>
            <img alt='logo' src={TITLE} />
          </div>
        </div>
        {scrollTop && <div className='keep' />}
        <nav className={scrollTop ? 'nav fixed' : 'nav'}>
          {scrollTop && (
            <div className='nav-logo'>
              <img src={LOGO} alt='logo' className='nav-logo_img' />
              <img src={NAVLOGO} alt='天慈生物' className='nav-logo_title' />
            </div>
          )}
          <ul className='nav-container'>
            {RouteConfig.filter(v => !v.hide).map(v => {
              return (
                <li
                  key={v.path}
                  onClick={() => {
                    this.goPage(v.path);
                  }}
                  className={
                    (
                      v.path === '/'
                        ? v.path === pathname
                        : v.key
                        ? v.key.find(r => pathname.includes(r))
                        : pathname.includes(v.path)
                    )
                      ? 'active'
                      : ''
                  }
                >
                  {v.icon ? (
                    <img
                      src={
                        v.path === '/' && v.path === pathname
                          ? v.activeIcon
                          : v.icon
                      }
                      alt={v.name}
                    />
                  ) : (
                    <React.Fragment>
                      <p className='nav-item_alias'>{v.alias}</p>
                      <p>{v.name}</p>
                    </React.Fragment>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    );
  }
}

export default withRouter(Header);
