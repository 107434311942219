import axios from 'axios';
import Cookies from 'js-cookie';

const fail = {
  code: -1,
  message: '操作失败',
};

const instance = axios.create({
  // baseURL: "http://localhost:8001/",
  timeout: 5 * 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${Cookies.get('_token')}`;
    return config;
  },
  (error) => {
    return fail;
  }
);

instance.interceptors.response.use(
  (res) => res.data,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          Cookies.remove('_token');
          sessionStorage.removeItem('_user');
          window.location.href = '/login';
          return false;
        default:
          return fail;
      }
    }
    return fail;
  }
);

export function Get(url, data) {
  return instance.get(process.env.REACT_APP_PREFIX + url, {
    params: data,
  });
}
export function Post(url, data) {
  return instance.post(process.env.REACT_APP_PREFIX + url, data);
}
