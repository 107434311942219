import 'core-js/es'; // 兼容ie9以上
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { HashRouter as Router } from 'react-router-dom';
import App from '@views';
import stores from '@store';
import '@styles/reset.less';
import '@styles/common.less';

ReactDOM.render(
  <Provider {...stores}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
