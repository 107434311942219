import { observable, action, runInAction } from 'mobx';
import { Get } from '@api';
import { ArticleUrl } from '@const/url';

class ArticleStore {
  @observable articleList = [];
  @observable articleTotal = 0;
  @observable articleQuery = {
    articleType: undefined,
    page: 1,
    pageSize: 5,
  };

  @action getArticleList = async () => {
    const { result, code } = await Get(ArticleUrl.list, this.articleQuery);
    runInAction(() => {
      if (code === 0) {
        this.articleList = [...this.articleList, ...result.items];
        this.articleTotal = result.totalElement;
      }
    });
  };

  @action updateParam = async param => {
    this.articleQuery = { ...this.articleQuery, ...param };
    this.articleList = [];
    this.articleTotal = 0;
    sessionStorage.setItem('_type', this.articleQuery.articleType);
    await this.getArticleList();
  };

  @action addPage = async () => {
    this.articleQuery = {
      ...this.articleQuery,
      page: this.articleQuery.page + 1,
    };
    await this.getArticleList();
  };
}

export default new ArticleStore();
